@font-face {
  font-family: 'piper';
  src: url('FZFWTongQPOPTJW.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   url('FZFWTongQPOPTJW.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}  
body {
  margin: 0;
  font-family: piper, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 28px !important;
  background: #f1ead7;
}
button { 

  background-color:#983a34 !important; 
  font-size: 28px !important;

} 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    font-size: 28px;
}

.footer {
  text-align: center;
    text-transform: uppercase;
    background-image: linear-gradient(#f1ead7, #f1ead7, #6d9151);

}